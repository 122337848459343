import React from 'react';
import './style.css';

function Zigzag() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
            <h1 className="h2 mb-4">Discover the Power of Journaling</h1>
            <p className="text-xl text-gray-500">Journaling is more than a daily routine—it's a strategic tool for growth. Reflect on your day, track your progress, and manage emotions effectively. Dailyala offers a sanctuary for your thoughts, aiding in decision-making and fostering innovation.</p>
          </div>

          {/* Items */}
          <div className="grid gap-20">

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img src="/questionssection.webp" alt="Features 01" className="max-w-full mx-auto md:max-w-none h-auto resize-image" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-gray-600 mb-2">Explore new ideas</div>
                  <h3 className="h3 mb-3">Daily Questions to Spark Insight</h3>
                  <p className="text-xl text-gray-500 mb-4">Not sure what to write? Start with a daily question to ignite your thoughts and keep you engaged through your journey.</p>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl" data-aos="fade-up">
                <img src="/streaksection.webp" alt="Features 02" className="max-w-full mx-auto md:max-w-none h-auto resize-image" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="font-architects-daughter text-xl text-gray-600 mb-2">A new habit</div>
                  <h3 className="h3 mb-3">Build Consistency</h3>
                  <p className="text-xl text-gray-500 mb-4">Dailyala motivates you with streaks to build and maintain a regular journaling habit that fits seamlessly into your busy schedule.</p>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img src="/aisection.webp" alt="Features 03" className="max-w-full mx-auto md:max-w-none h-auto resize-image" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-gray-600 mb-2">Your personal advisor</div>
                  <h3 className="h3 mb-3">AI-Powered Reflections</h3>
                  <p className="text-xl text-gray-500 mb-4">Dive deep into your journal with AI-powered search capabilities. Ask questions like "When did I last feel overwhelmed?" and get insights that help you navigate your entrepreneurial journey.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Zigzag;
