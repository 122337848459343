import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext'; // Adjust path as needed
import Home from './pages/HomePage';
import SignIn from './pages/SignIn';
import ReflectionsList from './pages/ReflectionList';
import ReflectionCreationPage from './pages/ReflectionCreationPage';
import DailyQuestionPage from './pages/DailyQuestionPage';
import SignUpPage from './pages/SignUpPage';
import AccountPage from './pages/AccountPage';
import SubscriptionPage from './pages/SubscriptionPage';
import SubscriptionSuccessPage from './pages/SubscriptionSuccessPage';
import AskAI from './pages/Assistant';
import OnboardingQuestionPage from './pages/OnboardingQuestion';

import ReactGA from "react-ga4";

// Initialize Google Analytics with your tracking ID
const TRACKING_ID = 'G-TWPMG7TQH0'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(TRACKING_ID);

// Component to track page views
const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview to Google Analytics when the location changes
    ReactGA.send("pageview");
  }, [location]);

  return null;
};

function App() {
  
  return (
    <AuthProvider> {/* Wrap all routes within AuthProvider */}
      <Router>
        <AnalyticsTracker />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/reflections" element={<ReflectionsList />} />
          <Route path="/createreflection" element={<ReflectionCreationPage />} />
          <Route path="/dailyquestion" element={<DailyQuestionPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/profile" element={<AccountPage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/subscription-success" element={<SubscriptionSuccessPage />} />
          <Route path="/assistant" element={<AskAI />} />
          <Route path="/onboarding" element={<OnboardingQuestionPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
