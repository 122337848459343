import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import api from '../services/api'; // Adjust the import path as needed

import googlelogo from '../img/googlelogo.jpg';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/users/signin', {
        email,
        password
      });
      // Assuming the API responds with a token
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userId', response.data.userId);
      // Redirect to a page after successful sign-in
      navigate('/reflections');
    } catch (error) {
      alert('Failed to sign in: ' + error.response.data.message); // Show error message
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = 'https://dailyala.app/api/auth/google'; // Adjust this URL to your backend endpoint
  };

  return (
    <div className="max-w-md mx-auto mt-10 px-4 py-8 border border-gray-300 shadow-md rounded-lg">
      <h1 className="text-xl font-bold mb-4">Sign In</h1>
      <p className="mt-1 text-lg text-gray-600 mb-4">Welcome back</p>
      <form onSubmit={handleSignIn}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="you@example.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="••••••••"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="text-white bg-black hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Sign In</button>
      </form>
      <div className="flex items-center my-4">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="mx-2 text-gray-500">OR</span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>
      <button
        onClick={handleGoogleLogin}
        className="w-full flex justify-center items-center py-2.5 border border-gray-300 rounded-lg text-sm font-medium"
      >
        <img src={googlelogo} alt="Google Icon" className="w-6 h-6 mr-2" />
        Continue with Google
      </button>
      <p className="mt-4 text-sm text-gray-600">
        Don’t have an account?{' '}
        <Link to="/signup" className="text-blue-600 hover:underline">Sign up</Link>
      </p>
    </div>
  );
}

export default SignIn;
