import React,  { useState } from 'react';
import api from '../services/api'; // Adjust the import path as needed

function Newsletter() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/newsletter', {
        email
      });

      setMessage('Thanks for subscribing!');
    } catch (error) {
      console.error('Error:', error);
      setMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <section className="mb-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* CTA box */}
        <div className="relative bg-black py-10 px-8 md:py-16 md:px-12" data-aos="fade-up">

          {/* Background illustration */}
          <div className="absolute right-0 top-0 -ml-40 pointer-events-none" aria-hidden="true">
            {/* Background SVG omitted for simplicity, add your own SVG code here */}
          </div>

          <div className="relative flex flex-col lg:flex-row justify-between items-center">

            {/* CTA content */}
            <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-1/2">
              <h3 className="h3 text-white mb-2">Stay in the loop</h3>
              <p className="text-gray-300 text-lg">Join our newsletter to get top news before anyone else.</p>
            </div>

            {/* CTA form */}
            <form className="w-full lg:w-1/2" onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none">
                <input type="email" className="w-full appearance-none bg-white border border-gray-200 focus:border-gray-100 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-gray-600 placeholder-gray-400" placeholder="Your best email…" aria-label="Your best email…"                   value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required />
                <button type="submit" className="btn text-gray-700 bg-white hover:bg-white shadow">Subscribe</button>
              </div>
              {message && <p className="text-center text-gray-200 lg:text-left mt-2 opacity-100 text-sm">{message}</p>}
            </form>

          </div>

        </div>

      </div>
    </section>
  );
}

export default Newsletter;
