import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Typography, Button, Container } from '@mui/material';

function SubscriptionSuccessPage() {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/reflections');  // Navigate to the home page or dashboard
    };

    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Congratulations on taking a step forward!
                </Typography>
                <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
                    You have successfully subscribed to our Premium Plan. Continue your journey to self-improvement with all the new features now unlocked for you.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleBackToHome}>
                    Go to your Reflections
                </Button>
            </Paper>
        </Container>
    );
}

export default SubscriptionSuccessPage;
