import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper, Snackbar } from '@mui/material';

import api from '../services/api'; // Adjust the import path as needed

import ReactQuill from 'react-quill'; // Import Quill
import 'react-quill/dist/quill.snow.css'; // Quill styles

function OnboardingQuestionPage() {
    const [reflection, setReflection] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSubmitReflection = async () => {
        if (!reflection.trim()) {
            setError('Please enter your reflection.');
            return;
        }

        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setError('You are not logged in.');
                return;
            }

            await api.post('/reflections', {
                content: reflection,
            }, {
                headers: { 'x-auth-token': token }
            });

            // Show success message
            setOpenSnackbar(true);
            setReflection('');
            setError('');

            // Delay navigation for 1 seconds to allow the user to see the message
            setTimeout(() => {
                navigate('/reflections');
            }, 2000);
        } catch (error) {
            console.error('Error submitting reflection:', error);
            setError('Unable to submit your reflection.');
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container component="main" maxWidth="lg" sx={{
            display: 'flex',         // Use flex layout
            flexDirection: 'column', // Stack children vertically
            minHeight: '100vh',      // Minimum height of viewport
            justifyContent: 'center', // Center vertically
            alignItems: 'center'     // Center horizontally
          }}>
            <Paper elevation={3} sx={{ p: 2, mb: 4, width: isMobile ? '95%' : '50%', maxWidth: '100%' }}>
                <Typography component="h3" variant="h7" sx={{ mt: 2, mb: 2 }}>
                    Let's create your first reflection!
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h2" variant="h6" sx={{ mt: 2, mb: 2 }}>
                        What is the biggest challenge you are currently trying to solve?
                    </Typography>
                    <ReactQuill
                        theme="snow"
                        value={reflection}
                        onChange={setReflection}
                        style={{ width: '100%', height: '400px', marginBottom: '20px', maxWidth: isMobile ? '100%' : '600px' }}
                        placeholder="What's on your mind?"
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3, 
                            mb: 2,
                            bgcolor: 'black', // Set background color to black
                            color: 'white', // Set text color to white
                            '&:hover': {
                                bgcolor: 'darkgrey' // Set background color to dark grey on hover
                            }
                        }}
                        onClick={handleSubmitReflection}
                    >
                        Save Reflection
                    </Button>
                </Box>
            </Paper>
            {/* Snackbar for success message */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message="Your reflection has been submitted!"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </Container>
    );
}

export default OnboardingQuestionPage;
