import { Link } from 'react-router-dom';
import MobileMenu from './mobile-menu';

function Header() {
  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          <div className="shrink-0 mr-4">
            <Link to="/" aria-label="Cruip">
            <h1 className="text-2xl font-bold text-left mt-6">Dailyala</h1>
            </Link>
          </div>
          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                  <a
                      href="/blog/"
                      className="font-medium text-gray-600 hover:text-gray-700 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                  >
                      Blog
                  </a>
              </li>
              <li>
                <Link
                  to="/signin"
                  className="font-medium text-gray-600 hover:text-gray-700 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  Sign in
                </Link>
              </li>
              <li>
                <Link to="/signup" className="btn-sm text-white bg-gray-700 hover:bg-black ml-3">
                  Try Free
                </Link>
              </li>
            </ul>
          </nav>
          <MobileMenu />
        </div>
      </div>
    </header>
  );
}

export default Header;
