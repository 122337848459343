import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

import api from '../services/api'; // Adjust the import path as needed

import googlelogo from '../img/googlelogo.jpg';

function SignUpPage() {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Message for Snackbar

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!firstName || !email || !password) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const response = await api.post('/users/signup', {
        firstName,
        email,
        password
      });
      const { token, userId } = response.data;
      
      // Assuming you have a context or a way to maintain user session
      localStorage.setItem('authToken', token);  // Storing the token in localStorage
      localStorage.setItem('userId', userId);
      // Show the success message in Snackbar
      setSnackbarMessage('Account created successfully!');
      setOpenSnackbar(true);
      
      // Delay navigation to allow the user to see the success message
      setTimeout(() => {
        navigate('/onboarding');  // Redirect to reflections page
      }, 2000);
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.response?.data?.message || 'Failed to sign up.');
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = 'https://dailyala.app/api/auth/google'; // Adjust this URL to your backend endpoint
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="max-w-md mx-auto mt-10 px-4 py-8 border border-gray-300 shadow-md rounded-lg">
      <h1 className="text-xl font-bold mb-4">Sign Up</h1>
      <p className="mt-1 text-lg text-gray-600 mb-4">Start your journey now</p>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <form onSubmit={handleSignUp}>
        <div className="mb-4">
          <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            id="firstName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="First Name"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="you@example.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="••••••••"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="text-white bg-black hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Sign Up</button>
      </form>
      <div className="flex items-center my-4">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="mx-2 text-gray-500">OR</span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>
      <button
        onClick={handleGoogleLogin}
        className="w-full flex justify-center items-center py-2.5 border border-gray-300 rounded-lg text-sm font-medium"
      >
        <img src={googlelogo} alt="Google Icon" className="w-6 h-6 mr-2" />
        Continue with Google
      </button>
      <p className="mt-4 text-sm text-gray-600">
        Already have an account?{' '}
        <Link to="/signin" className="text-blue-600 hover:underline">Sign In</Link>
      </p>
      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </div>
  );
}

export default SignUpPage;
