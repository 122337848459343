    import React, { useState, useEffect } from 'react';
    import { Link } from 'react-router-dom';
    import { format } from 'date-fns';

    import AlertDialog from '../components/AlertDialog';

    import Button from '@mui/material/Button';
    import AccountCircleIcon from '@mui/icons-material/AccountCircle';
    import Menu from '@mui/material/Menu';
    import MenuItem from '@mui/material/MenuItem';
    import DeleteIcon from '@mui/icons-material/Delete';
    import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

    import api from '../services/api'; // Adjust the import path as needed

    import { useNavigate } from 'react-router-dom';

    import noReflectionsImage from '../img/noReflection.png';

    import '../styles/reflectionList.css';

    import DOMPurify from 'dompurify';

    function ReflectionsList() {
        const [reflections, setReflections] = useState([]);
        const [streak, setStreak] = useState(0);
        const [searchQuery, setSearchQuery] = useState('');
        const [isSearching, setIsSearching] = useState(false);
        const [isDialogOpen, setDialogOpen] = useState(false);
        const [dialogTitle, setDialogTitle] = useState('');
        const [dialogMessage, setDialogMessage] = useState('');
        const [anchorEl, setAnchorEl] = useState(null);
        const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
        const [reflectionToDelete, setReflectionToDelete] = useState(null);
        const [accountType, setAccountType] = useState(''); // Add state for account type
        const [isMobile, setIsMobile] = useState(false);
        const navigate = useNavigate();

        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const userId = urlParams.get('userId');
            if (token && userId) {
                localStorage.setItem('authToken', token);
                localStorage.setItem('userId', userId);
                navigate('/reflections');
            }
        }, [navigate]);

        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
            };
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const handleMenuClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        const handleNavigate = (path) => {
            navigate(path);
            handleMenuClose();
        };

        const checkTrialLimit = async () => {
            try {
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    console.warn("User ID not found in localStorage. Might need to log in again.");
                    return;
                }
                const response = await api.get(`/users/checkTrialLimit/${userId}`);
                const trialLimitReached = response.data.trialLimitReached;
                const accountType = response.data.accountType;

                if (accountType === 'trial' && trialLimitReached) {
                    setDialogTitle("Trial Limit Reached");
                    setDialogMessage("You've reached your trial limit of reflections. Subscribe to continue journaling.");
                    setDialogOpen(true);
                }
            } catch (error) {
                console.error('Error checking trial limit:', error);
                setDialogTitle("Error");
                setDialogMessage("Unable to check trial limit.");
                setDialogOpen(true);
            }
        };

        function transformLinks(htmlContent) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlContent, 'text/html');
        
            doc.querySelectorAll('a').forEach(link => {
                const href = link.getAttribute('href');
                if (href && !href.includes('http://') && !href.includes('https://')) {
                    link.href = `http://${href}`; // This assumes you want all links to be HTTP if not specified
                }
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
            });
        
            return doc.body.innerHTML;
        }

        const fetchUserDetails = async () => {
            try {
                const { data } = await api.get('/users/me', {
                    headers: { 'x-auth-token': `${localStorage.getItem('authToken')}`, 'ngrok-skip-browser-warning': 'true' }
                });
                setAccountType(data.accountType);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        const fetchReflections = async () => {
            try {
                const { data } = await api.get('/reflections', {
                    headers: { 'x-auth-token': `${localStorage.getItem('authToken')}`, 'ngrok-skip-browser-warning': 'true' }
                });
        
                const reflectionsWithSanitizedContent = data.map(reflection => ({
                    ...reflection,
                    content: DOMPurify.sanitize(transformLinks(reflection.content))
                }));
        
                const sortedReflections = reflectionsWithSanitizedContent.sort((a, b) => 
                    new Date(b.createdAt) - new Date(a.createdAt)
                );
        
                setReflections(sortedReflections);
                setIsSearching(false);  // Reset search status after fetching reflections
            } catch (error) {
                console.error('Error fetching reflections:', error);
                alert('Error fetching reflections');
            }
        };

        const fetchStreak = async () => {
            try {
                const { data } = await api.get(`/streak/${localStorage.getItem('userId')}`, {
                    headers: { 'ngrok-skip-browser-warning': 'true' }
                });
                setStreak(data.streak);
            } catch (error) {
                console.error('Error fetching streak:', error);
                alert('Error fetching streak');
            }
        };

        useEffect(() => {
            fetchUserDetails();
            checkTrialLimit();
        }, []);
    
        useEffect(() => {
            fetchReflections();
            fetchStreak();
        }, []);

        const handleSearch = async (e) => {
            e.preventDefault();
            try {
                const { data } = await api.get(`/reflections/search?searchQuery=${searchQuery}`, {
                    headers: {  'x-auth-token': `${localStorage.getItem('authToken')}`, 'ngrok-skip-browser-warning': 'true' }
                });
                setReflections(data);
                setIsSearching(true);
            } catch (error) {
                console.error('Error performing search:', error);
                alert('Error performing search');
            }
        };

        const clearSearch = () => {
            setSearchQuery('');
            setIsSearching(false);
            fetchReflections(); // Assuming fetchReflections is adjusted to refetch all reflections
        };

        const goToProfile = () => {
            navigate('/profile');
        };

        const handleDeleteClick = (reflection) => {
            if (accountType === 'trial') {
                setDialogTitle("Delete Reflection");
                setDialogMessage("Trial accounts cannot delete reflections.");
                setDialogOpen(true);
            } else {
                setReflectionToDelete(reflection);
                setDeleteDialogOpen(true);
            }
        };
    
        const handleDeleteConfirm = async () => {
            try {
                const { data } = await api.delete(`/reflections/${reflectionToDelete._id}`, {
                    headers: { 'x-auth-token': `${localStorage.getItem('authToken')}` }
                });
                fetchReflections();
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Error deleting reflection:', error);
                alert('Error deleting reflection');
            }
        };

        return (
            <div>
                <AlertDialog
                    open={isDialogOpen}
                    onClose={() => setDialogOpen(false)}
                    title={dialogTitle}
                    message={dialogMessage}
                />
                <div className="container mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center my-4">
                        <h1 className="text-2xl font-bold text-left mt-6">Dailyala</h1>
                        <div className="flex items-center">
                            <AccountCircleIcon fontSize="large" onClick={goToProfile} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div className="my-4 text-left">
                        <p>You are {streak} days journaling</p>
                        {streak === 0 && <p>Start your journaling streak today!</p>}
                        {streak > 0 && streak < 5 && <p>Great start! Keep going to build your habit.</p>}
                        {streak >= 5 && <p>Awesome! You're on a roll, keep up the good work!</p>}
                    </div>
                    <div className={`flex ${isMobile ? 'flex-col items-start' : 'justify-between items-center'} my-4`}>
                        <div className={`flex-none ${isMobile ? 'mb-4' : ''}`}>
                            <Button
                            variant="contained"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                            sx={{
                                bgcolor: 'black', // Set the button background color to black
                                color: 'white', // Set the text color to white for better visibility
                                '&:hover': {
                                    bgcolor: 'darkgrey' // Optional: change background color on hover
                                }
                            }}
                            >
                            New Reflection
                            </Button>
                            <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            >
                            <MenuItem onClick={() => handleNavigate('/dailyquestion')}>Answer a Daily Question</MenuItem>
                            <MenuItem onClick={() => handleNavigate('/createreflection')}>Create a New Reflection</MenuItem>
                            </Menu>
                            <Button
                                variant="contained"
                                onClick={() => handleNavigate('/assistant')}
                                sx={{
                                    marginLeft: '10px',  // Maintains the right margin
                                    bgcolor: 'black', // Set the button background color to black
                                    color: 'white', // Set the text color to white for better visibility
                                    '&:hover': {
                                        bgcolor: 'grey' // Optional: change background color on hover
                                    }
                                }}
                            >
                                Chat With AI
                            </Button>
                        </div>
                        <div className={`flex-grow ${isMobile ? 'w-full' : ''}`}>
                            <form onSubmit={handleSearch} className={`flex ${isMobile ? 'flex-col' : 'justify-end items-center gap-2'} mb-4`}>
                                <input
                                    type="text"
                                    placeholder="Search reflections..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className={`form-input ${isMobile ? 'mb-2' : ''}`}
                                />
                                <button
                                    type="submit"
                                    className="btn"
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '8px 16px', // Adjust padding to match MUI Button
                                        borderRadius: '4px', // Adjust border-radius to match MUI Button
                                        border: 'none', // Remove default border
                                        cursor: 'pointer', // Change cursor to pointer
                                    }}
                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'grey'}
                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'black'}
                                >
                                    Search
                                </button>
                                {isSearching && (
                                    <button onClick={clearSearch} className="btn bg-red-500 text-white">Clear Search</button>
                                )}
                            </form>
                        </div>
                    </div>
                    {reflections.length > 0 ? (
                    reflections.map(reflection => (
                        <div key={reflection._id} className="reflection-item p-4 border border-gray-200 rounded-lg mb-4 flex justify-between items-center">
                            <div>
                                {reflection.question && (
                                    <h3 className="font-semibold">{reflection.question.questionText}</h3>
                                )}
                                <p dangerouslySetInnerHTML={{ __html: reflection.content }}></p>
                                <i>{format(new Date(reflection.createdAt), 'PPP')}</i>
                            </div>
                            {accountType !== 'trial' && (
                                <IconButton aria-label="delete" onClick={() => handleDeleteClick(reflection)} color="gray">
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                    ))
                ) : (
                <div className="flex flex-col items-center justify-center mt-20 pt-10">
                    <img src={noReflectionsImage} alt="No Reflections" style={{ maxWidth: '200px', maxHeight: '300px', width: '100%', height: 'auto', borderRadius: '10px' }} />
                    <p className="mt-4 text-xl">You have no reflections yet. Start journaling today!</p>
                </div>
                )}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Reflection"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this reflection?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                </div>
            </div>
        );
    }

    export default ReflectionsList;
