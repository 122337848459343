import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Paper, Button, Breadcrumbs, Link } from '@mui/material';
import api from '../services/api'; // Adjust the import path as needed

function SubscriptionPage() {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await api.get('/stripe/products', {
                    headers: {
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                setProducts(response.data);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    const handleSubscribe = async (priceId) => {
        try {
            const userId = await localStorage.getItem('userId');
            
            const response = await api.post('/stripe/create-checkout-session', {
                priceId,
                userId
            });
            const { url } = response.data;
    
            if (url) {
                window.open(url, "_blank");
            }
        } catch (error) {
            console.error("Error creating checkout session: ", error);
        }
    };

    return (
        <Container component="main" maxWidth="md" sx={{
            display: 'flex',         // Use flex layout
            flexDirection: 'column', // Stack children vertically
            minHeight: '100vh',      // Minimum height of viewport
            justifyContent: 'center' // Center vertically
          }}>
            <Paper elevation={3} sx={{ p: 2, mb: 4, width: '100%', mx: 'auto' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link component={RouterLink} color="inherit" to="/profile">
                       My Account
                    </Link>
                    <Typography color="text.primary">Subscription</Typography>
                </Breadcrumbs>
                <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Choose a Subscription Plan
                    </Typography>
                    {products.map((product, index) => (
                        <Box key={index} sx={{ width: '100%', mt: 2 }}>
                            <Typography variant="h6" sx={{ mt: 2 }}>{product.name}</Typography>
                            {product.prices.map((price) => (
                                <Button
                                    key={price.id}
                                    variant="contained"
                                    sx={{ my: 1, width: '100%' }}
                                    onClick={() => handleSubscribe(price.id)}
                                >
                                    {`${price.currency.toUpperCase()} ${price.unit_amount / 100} - ${price.recurring.interval}`}
                                </Button>
                            ))}
                        </Box>
                    ))}
                </Box>
            </Paper>
        </Container>
    );
}

export default SubscriptionPage;
