// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('authToken');
      console.log('Token received:', token);
      setIsSignedIn(!!token);
    };

    checkAuthStatus();
  }, []);

  const signIn = (token) => {
    localStorage.setItem('authToken', token);
    setIsSignedIn(true);
  };

  const signOut = () => {
    localStorage.removeItem('authToken');
    setIsSignedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isSignedIn, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
