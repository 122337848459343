import React from 'react';
import Header from '../components/Header'
import Hero from '../components/Hero';       // Adjust the path according to your directory structure
import Zigzag from '../components/ZigZag';   // Adjust the path according to your directory structure
import Testimonials from '../components/Testimonials';  // Adjust the path according to your directory structure
import Newsletter from '../components/Newsletter';      // Adjust the path according to your directory structure
import Footer from '../components/Footer';   // Adjust the path according to your directory structure

function HomePage() {
  return (
    <div>
      <Header />
      <Hero />
      <Zigzag />
      <Testimonials />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default HomePage;
