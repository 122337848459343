import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography, Paper, CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // For a styled back arrow icon
import { useNavigate } from 'react-router-dom';

const FullHeightPaper = styled(Paper)({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const ChatBox = styled(Box)({
    overflow: 'auto',
    padding: '16px',
    flexGrow: 1,
});

// Updated UserMessage and AIMessage styles
const UserMessage = styled(Typography)(({ theme }) => ({
    alignSelf: 'flex-end',
    background: '#e0e0e0',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 'fit-content',
    minWidth: '10%',
    marginLeft: 'auto',
    marginBottom: theme.spacing(1),
    wordBreak: 'break-word',
    textAlign: 'right',
}));

const AIMessage = styled(Typography)(({ theme }) => ({
    alignSelf: 'flex-start',
    background: '#ffffff',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 'fit-content',
    minWidth: '10%',
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
    wordBreak: 'break-word',
}));

const AskAI = () => {
    const navigate = useNavigate();
    const [question, setQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const [loading, setLoading] = useState(false);
    const bottomRef = useRef(null);

    const handleBack = () => {
        navigate(-1); // Navigates back to the previous page
    };

    useEffect(() => {
        fetchChatHistory();
    }, []);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory]);

    const fetchChatHistory = async () => {
        try {
            const res = await axios.get(`https://dailyala.app/ai/chat-history?user_id=${userId}`);
            setChatHistory(res.data);
        } catch (err) {
            console.error('Failed to fetch chat history:', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!question.trim()) return;
        setLoading(true);
        try {
            const res = await axios.post('https://dailyala.app/ai/ask-ai', { question, user_id: userId });
            const newEntry = { question, response: res.data.response };
            setChatHistory([...chatHistory, newEntry]);
            setQuestion('');
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    };

    return (
        <Container component="main" maxWidth="md">
            <FullHeightPaper elevation={3}>
                <Box sx={{ padding: '16px' }}>
                    <IconButton onClick={handleBack} sx={{ marginBottom: '16px' }}>
                        <ArrowBackIcon />
                    </IconButton>
                    {/* Additional content or breadcrumbs could go here */}
                </Box>
                <ChatBox>
                    {chatHistory.map((chat, index) => (
                        <React.Fragment key={index}>
                            <UserMessage>{chat.question}</UserMessage>
                            <AIMessage>{chat.response}</AIMessage>
                        </React.Fragment>
                    ))}
                    <div ref={bottomRef} />
                </ChatBox>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 1, p: 2 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="How can I help you today?"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        sx={{
                            flexGrow: 1,
                            '& .MuiInputLabel-root': { // Targeting the label
                                color: 'black', // Default label color
                                '&.Mui-focused': { // Label color when the input is focused
                                    color: 'black', // Change to any color you like
                                }
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { // Default border color
                                    borderColor: 'black',
                                },
                                '&.Mui-focused fieldset': { // Border color when the input is focused
                                    borderColor: 'black', // Change to any color you like
                                }
                            }
                        }}
                    />
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit" 
                        disabled={loading}
                        sx={{
                            bgcolor: 'black', // Set background color to black
                            '&:hover': {
                                bgcolor: 'darkgray', // Optional: change on hover
                            },
                        }}
                    >
                            {loading ? <CircularProgress size={24} /> : 'Send'}
                    </Button>
                </Box>
            </FullHeightPaper>
        </Container>
    );
};

export default AskAI;
