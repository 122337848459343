import React from 'react';

function Testimonials() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Don't take our word for it</h2>
            <p className="text-xl text-gray-500">"Reflecting daily helps me stay grounded and focused, even amidst chaos." – Inspired by Tim Ferriss, a champion of reflective practices.</p>
          </div>

          {/* Testimonials */}
          <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-3 lg:gap-6 items-start lg:max-w-none">

            {/* 1st testimonial */}
            <div className="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up">
              <div>
                {/* <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src="/images/testimonial-01.jpg" width="48" height="48" alt="Testimonial 01" />
                </div> */}
              </div>
              <blockquote className="text-lg text-gray-400 grow">— As a Creator, my days are a whirlwind of meetings and decision-making. Dailyala has become my pocket sanctuary. Reflecting on my day helps me decompress and the AI-powered search is like having a personal advisor reminding me of my past insights. It's truly helped me become more grounded and strategic in my journey.</blockquote>
              <div className="text-gray-400 font-medium mt-6 pt-5 border-t border-gray-700">
                <cite className="text-gray-200 not-italic">Samantha K.</cite> - Creator
              </div>
            </div>

            {/* 2nd testimonial */}
            <div className="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="200">
              <div>
                {/* <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src="/images/testimonial-02.jpg" width="48" height="48" alt="Testimonial 02" />
                </div> */}
              </div>
              <blockquote className="text-lg text-gray-400 grow">— Launching a creative agency was my dream, but the constant pressure to innovate can be exhausting. With Dailyala, I've found a rhythm to my reflections, and the daily questions prompt me to explore new angles and ideas. It's not just a journal; it's a tool that sharpens my creative edge every day.</blockquote>
              <div className="text-gray-400 font-medium mt-6 pt-5 border-t border-gray-700">
                <cite className="text-gray-200 not-italic">Alexis R.</cite> - Founder of DarkSpace Agency
              </div>
            </div>

            {/* 3rd testimonial */}
            <div className="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="400">
              <div>
                {/* <div className="relative inline-flex flex-col mb-4">
                  <img className="rounded-full" src="/images/testimonial-03.jpg" width="48" height="48" alt="Testimonial 03" />
                </div> */}
              </div>
              <blockquote className="text-lg text-gray-400 grow">— Dailyala has revolutionized how I track my entrepreneurial journey. As a serial founder, I've always struggled with consistency in my reflection habits. The streak feature keeps me accountable, and the ability to look back on 'When did I last feel overwhelmed?' is invaluable for personal growth.</blockquote>
              <div className="text-gray-400 font-medium mt-6 pt-5 border-t border-gray-700">
                <cite className="text-gray-200 not-italic">Jordan T.</cite> - Serial Founder
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Testimonials;
