import React from 'react';

function Hero() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

        {/* Hero content */}
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4">Unleash Your Full Potential</h1>
            <p className="text-xl text-gray-500 mb-8">Every innovator needs space to reflect. Dailyala helps founders and creators like you maintain clarity and focus through daily guided reflections and insights.</p>
            <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div>
                <a className="btn text-white bg-black hover:bg-gray-800 w-full mb-4 sm:w-auto sm:mb-0" href="/signup">Try Dailyala Free</a>
              </div>
              {/* <div>
                <a className="btn text-white bg-gray-700 hover:bg-gray-600 w-full sm:w-auto sm:ml-4" href="#0">Learn more</a>
              </div> */}
            </div>
          </div>

        </div>

      </div>
    </section>
  );
}

export default Hero;
