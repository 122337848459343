import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import api from '../services/api';
import { format } from 'date-fns';
import {
  Container, Typography, Box, Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Breadcrumbs, Link } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext'; // Ensure correct import path
import Footer from '../components/Footer';   // Adjust the path according to your directory structure

function AccountPage() {
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: '',
    accountType: '',
    subscriptionPlan: '',
    stripeSubscriptionId: '',
    subscriptionEndDate: null,
    subscriptionStartDate: null,
    reflectionsCount: 0
  });
  const [error, setError] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState(false); // Correctly named and initialized

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/users/me', {
        headers: { 'x-auth-token': `${token}`, 'ngrok-skip-browser-warning': 'true' }
      });
      setUserDetails({
          name: response.data.firstName,
          accountType: response.data.accountType,
          subscriptionPlan: response.data.subscriptionPlan,
          stripeSubscriptionId: response.data.stripeSubscriptionId,
          subscriptionEndDate: response.data.subscriptionEndDate,
          subscriptionStartDate: response.data.subscriptionStartDate,
          reflectionsCount: response.data.reflectionsCount,
          createdAt: format(new Date(response.data.createdAt), 'PPP')
      });
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Unable to fetch user details.');
    }
  };

  useEffect(() => {
    fetchUserDetails();
}, []);

  const logout = () => {
    localStorage.removeItem('authToken');
    signOut();
    navigate('/signin');
  };

  const handleCancelSubscriptionPress = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

const cancelSubscription = async () => {
    const token = localStorage.getItem('authToken');
    try {
        const response = await api.post('/stripe/cancel-subscription', {
            stripeSubscriptionId: userDetails.stripeSubscriptionId,
        }, {
            headers: { 'x-auth-token': `${token}` }
        });

        if (response.data.success) {
            alert("Your subscription has been canceled. You will have access until the end of your current billing period.");
            fetchUserDetails();
        } else {
            alert("There was an issue canceling your subscription. Please try again.");
        }
    } catch (error) {
        console.error('Error canceling subscription:', error);
        alert('There was an issue canceling your subscription. Please try again.');
    }
};

const SubscriptionInfo = () => {
    let content = <p>Checking subscription status...</p>;

    const formatEndDate = (endDate) => {
        if (!endDate || isNaN(new Date(endDate).getTime())) {
            return 'an unknown date';
        }
        return format(new Date(endDate), 'PPP');
    };

    switch (userDetails.accountType) {
        case 'trial':
            content = (
                <div>
                    <p>You have <span style={{ fontWeight: 'bold' }}>{10 - userDetails.reflectionsCount}</span> reflections left on your trial.</p>
                    <Button sx={{ mt: 2 }} onClick={() => navigate('/subscription')}>Choose a Plan</Button>
                </div>
            );
            break;
        case 'paid':
            content = (
                <div>
                    <p>You subscribed on {userDetails.subscriptionStartDate ? formatEndDate(userDetails.subscriptionStartDate) : 'Loading...'}</p>
                    <Button sx={{ mt: 2 }} onClick={handleCancelSubscriptionPress}>Cancel Subscription</Button>
                </div>
            );
            break;
        case 'canceled':
            content = (
                <div>
                    <p>Your subscription finished on {userDetails.subscriptionEndDate ? formatEndDate(userDetails.subscriptionEndDate) : 'Loading...'}</p>
                    <Button sx={{ mt: 2 }}onClick={() => navigate('/subscription')}>Subscribe Again</Button>
                </div>
            );
            break;
        case 'cancelRequest':
          content = (
              <div>
                  <p>Your subscription cancellation is pending. Your subscription will end on {formatEndDate(userDetails.subscriptionEndDate)}.</p>
              </div>
          );
        break;
        default:
            content = <p>Subscription status unknown.</p>;
            break;
    }
    return <div>{content}</div>;
};

  return (
    <Container component="main" maxWidth="sm" sx={{
        display: 'flex',         // Use flex layout
        flexDirection: 'column', // Stack children vertically
        minHeight: '100vh',      // Minimum height of viewport
        justifyContent: 'center' // Center vertically
      }}>
        <Paper elevation={3} sx={{ p: 2, mb: 4, width: '100%', maxWidth: 600, mx: 'auto' }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link component={RouterLink} color="inherit" to="/reflections">
                    See your Reflections
                </Link>
                <Typography color="text.primary">Account</Typography>
            </Breadcrumbs>
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom>
                My Account
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <Typography variant="h6" sx={{ mt: 3 }}>Personal</Typography>
                <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
                {userDetails.name}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                Since {userDetails.createdAt}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    You reflected <span style={{ fontWeight: 'bold' }}>{userDetails.reflectionsCount} </span> times about your life 
                </Typography>
                <Typography variant="h6" sx={{ mt: 4 }}>Subscription</Typography>
                <SubscriptionInfo />
                    <Dialog
                        open={isDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Cancel Subscription"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to cancel your subscription? Your subscription will remain active until the end of the current billing cycle.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Close
                            </Button>
                            <Button onClick={cancelSubscription} color="primary" autoFocus>
                                I Want to Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                <Button
                variant="contained"
                sx={{
                    mt: 3,
                    bgcolor: 'black', // Set the button background color to black
                    color: 'white', // Set the text color to white for better visibility
                    '&:hover': {
                        bgcolor: 'grey' // Optional: change background color on hover
                    }
                }}
                onClick={logout}
                >
                Logout
                </Button>
            </Box>
        </Paper>
    </Container>
  );
}

export default AccountPage;
