import React from 'react';
import { Link } from 'react-router-dom';  // Using react-router-dom for navigation

function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label="Cruip">
                  {/* Insert your logo SVG or image tag here */}
                  <span className="text-xl font-bold">Dailyala</span>
                </Link>
              </div>
              <div className="text-gray-400">
                Dailyala was created to help every innovator reflect, maintain clarity, and focus. Now you can create a better world.
              </div>
            </div>

            {/* Social links */}
            <div className="md:col-span-8 lg:col-span-7">
              <div className="flex justify-end">
                <ul className="flex mb-4 md:order-1 md:mb-0">
                  <li>
                    <Link to="/" className="flex justify-center items-center text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out" aria-label="Instagram">
                      {/* Insert your Instagram SVG or icon here */}
                      <span className="text-lg">Instagram</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Copyrights note */}
            <div className="text-gray-400 text-sm">&copy; {new Date().getFullYear()} Dailyala.app. All rights reserved.</div>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
